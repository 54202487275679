import React, { useState } from 'react';

export const myContext = React.createContext();

const ContextProvider = ({ children }) => {
  //context clears on refresh. Hitting the API to hydrate on refresh
  //seems overkill at the moment so I'm using local storage.
  //not in love with this solution so if you see this and want to change, please do.
  const [firstName, setFirstName] = useState(
    typeof window !== 'undefined' ? localStorage.getItem('firstName') : 'First '
  );
  //there is no window object for ssr so local storage causes issues
  const [lastName, setLastName] = useState(
    typeof window !== 'undefined' ? localStorage.getItem('lastName') : 'Last'
  );

  const [phone, setPhoneNumber] = useState(
    typeof window !== 'undefined'
      ? localStorage.getItem('phoneNumber')
      : '111-111-1111'
  );

  const [emailAddress, setEmail] = useState(
    typeof window !== 'undefined'
      ? localStorage.getItem('emailAddress')
      : 'your@emial.com'
  );
  const [selectedState, setSelectedState] = useState(
    typeof window !== 'undefined'
      ? localStorage.getItem('selectedState')
      : 'Select State'
  );
  const [hasEnteredData, setHasEnteredData] = useState(false);
  const [orderNumber, setOrderNumber] = useState('');

  return (
    //There are some slight speed advantages to using seperate getter/setter providers
    //but with how limited this gets used, it seems overkill.
    <myContext.Provider
      value={{
        firstName,
        lastName,
        emailAddress,
        selectedState,
        hasEnteredData,
        orderNumber,
        phone,
        changePhoneNumber: (str) => setPhoneNumber(str),
        changeOrderNumber: (str) => setOrderNumber(str),
        changeFirst: (str) => setFirstName(str),
        changeLast: (str) => setLastName(str),
        changeEmail: (str) => setEmail(str),
        changeSelectedState: (str) => setSelectedState(str),
        changeHasEnteredData: (bool) => setHasEnteredData(bool),
      }}
    >
      {children}
    </myContext.Provider>
  );
};

export default ContextProvider;
