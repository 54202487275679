import React from 'react';
import { Helmet } from 'react-helmet';

export default function Layout({ children }) {
  return (
    <div className="relative">
      <Helmet>
        <title>Hone Health - Checkout</title>
        <meta name="description" content="Hone Health Checkout" />
      </Helmet>
      <div className="bg-image">
        {children}
      </div>
    </div>
  );
}
