import './src/styles/global.css';
import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import ContextProvider from './src/components/utils/ContextProvider';
import Layout from './src/components/Layout';

const promise = loadStripe(process.env.GATSBY_STRIPE_PUBLIC_KEY);

export const wrapRootElement = ({ element }) => (
  <ContextProvider>
    <Layout>
      <Elements stripe={promise}>{element}</Elements>
    </Layout>
  </ContextProvider>
);
